'use client';
import Link from 'next/link';
import * as Icon from '@/ui/Icon';
import * as Styled from './styled';
import { IUser } from '@/types';
import { Config } from '@/hooks/useConfig';
import { IConfig } from '@/lib/configApi';
import { IS_RUNNING_ON_PREMISES } from '@/config/environment';

interface NavigationProps {
  currentUser?: IUser;
  config?: IConfig;
}

const ExternalLinkComponent: React.FC<{ children: React.ReactNode; slug: string }> = ({ children, slug }) => {
  const externalLink = `https://www.oxen.ai/${slug}`;

  if (IS_RUNNING_ON_PREMISES) {
    return (
      <a target="_blank" href={externalLink} rel="noopener noreferrer">
        {children}
      </a>
    );
  }

  return (
    <Link href={`/${slug}`} rel="noopener noreferrer">
      {children}
    </Link>
  );
};

const Navigation: React.FC<NavigationProps> = ({ currentUser, config }) => {
  const externalDocsLink = 'https://docs.oxen.ai';
  return (
    <Styled.Navigation>
      {/* <Link href="/">
        <Styled.NavigationItem>
          <span>Search</span>
          <Icon.Search />
        </Styled.NavigationItem>
      </Link>*/}
      <Link href="/explore">
        <Styled.NavigationItem>
          <Icon.Compass width={14} />
          <div>Explore</div>
        </Styled.NavigationItem>
      </Link>
      {config?.community_enabled && (
        <ExternalLinkComponent slug={'community'}>
          <Styled.NavigationItem>
            <Icon.Users width={14} />
            <div>Community</div>
          </Styled.NavigationItem>
        </ExternalLinkComponent>
      )}
      <a target="_blank" href={externalDocsLink} rel="noopener noreferrer">
        <Styled.NavigationItem>
          <Icon.InfoCircle width={14} />
          <div>Docs</div>
        </Styled.NavigationItem>
      </a>
      {/* <a target="_blank" href={externalFaqLink} rel="noopener noreferrer">
        <Styled.NavigationItem>
          <div>FAQ</div>
          <Icon.QuestionCircle />
        </Styled.NavigationItem>
      </a> */}
      <ExternalLinkComponent slug={'blog'}>
        <Styled.NavigationItem>
          <Icon.Book width={14} />
          <div>Blog</div>
        </Styled.NavigationItem>
      </ExternalLinkComponent>

      {!IS_RUNNING_ON_PREMISES && (
        <a href="/billing/plans" rel="noopener noreferrer">
          <Styled.NavigationItem>
            <Icon.CreditCard width={14} />
            <div>{currentUser ? 'Upgrade' : 'Pricing'}</div>
          </Styled.NavigationItem>
        </a>
      )}
    </Styled.Navigation>
  );
};

export const MaintenanceNavigation: React.FC<{}> = ({}) => {
  const externalDocsLink = 'https://docs.oxen.ai';
  return (
    <Styled.Navigation>
      <a target="_blank" href={externalDocsLink} rel="noopener noreferrer">
        <Styled.NavigationItem>
          <Icon.InfoCircle width={14} />
          <div>Docs</div>
        </Styled.NavigationItem>
      </a>
    </Styled.Navigation>
  );
};

export default Navigation;
